import { ResourcesProps } from './Resources'

export const getResourcesData = (data: any): ResourcesProps => {
    return {
        title: data.resources__title,
        text: data.resources__text,
        subtitle: data.resources__subtitle,
        downloads: mapDownloads(data.resources__downloads || [])
    }
}

const mapDownloads = (downloads: any[] = []): ResourcesProps['downloads'] =>
    downloads.map(download => {
        if (download.type === 'resourcepdf') return download
        return { name: download.label, file: download.url, type: download.type }
    })
