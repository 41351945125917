import { Box, Grid, Hidden } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { RichText } from 'common/components/molecules/RichText'
import { DownloadLink, DownloadLinkProps } from './DownloadLink'
export * from './utils'
export interface Download {
    name: string
    file: string
    notes?: string
    type?: DownloadLinkProps['type']
}

export interface ResourcesProps {
    title: string
    subtitle?: string
    text: string
    downloads: Download[]
}

export const Resources: React.FC<ResourcesProps> = ({
    text,
    title,
    subtitle,
    downloads = [],
}) => {
    const desktop = useDesktop()
    return (
        <>
            <Grid container data-testid='resources' component='section'>
                <Grid item xs={12} md={6}>
                    <Text
                        box={{ mb: 6, color: '#4DB7AB' }}
                        label={title}
                        variant='overline'
                        component='h1'
                    />
                    <Text
                        box={{ mb: 7 }}
                        html
                        label={text}
                        variant='h4'
                        component='h2'
                    />
                    {subtitle && (
                        <Text
                            box={{ mb: 7 }}
                            html
                            label={subtitle}
                            style={{ color: '#766F90', fontSize: '20' }}
                            component='p'
                        />
                    )}
                </Grid>
                <Hidden smDown>
                    <Grid item xs={12} md={1} />
                </Hidden>
                <Grid item xs={12} md={5}>
                    <Box mt={desktop ? 19 : 0}>
                        {downloads &&
                            downloads.map((download, index) => (
                                <Box borderBottom={`1px solid #EBEDF7`}>
                                    <DownloadLink
                                        key={download.name}
                                        text={download.name}
                                        url={download.file}
                                        type={download.type}
                                        notes={download.notes}
                                    />
                                    {download.notes && (
                                        <RichText
                                            color='textSecondary'
                                            html
                                            label={download.notes}
                                            variant='body2'
                                        />
                                    )}
                                </Box>
                            ))}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
