import { Box, Link, Typography } from '@material-ui/core'
import React from 'react'
import { ReactComponent as LinkIcon } from './Link.svg'
import { ReactComponent as ResourcesArrow } from './resourcesArrow.svg'
import { ReactComponent as ResourcesPdf } from './resourcesPdf.svg'

export interface DownloadLinkProps {
    text: string
    type?: 'link' | 'resourcepdf'
    url: string
    notes?: string
}

export const DownloadLink: React.FC<DownloadLinkProps> = ({
    text,
    url,
    type = 'resourcepdf',
}) => {
    return (
        <Link
            data-testid='downloadLink'
            href={url}
            style={{ textDecoration: 'none' }}
            target='_blank'
        >
            <Box
                alignItems='center'
                borderBottom={
                    type === 'resourcepdf' ? `0px` : `1px solid #EBEDF7`
                }
                display='flex'
                height={72}
            >
                {type === 'resourcepdf' ? <ResourcesPdf /> : <LinkIcon />}
                <Typography
                    style={{
                        color: '#5261AC',
                        fontSize: 16,
                        fontWeight: 600,
                        flex: 1,
                        marginLeft: 12,
                        lineHeight: 1.4,
                    }}
                >
                    {text}
                </Typography>
                <ResourcesArrow />
            </Box>
        </Link>
    )
}
